import { gql } from "@apollo/client"

export const createEpisode = gql`
  mutation CreateEpisode($input: CreateEpisodeInput!) {
    createEpisode(input: $input) {
      id
      lang
      status
      stage
      image
      createdAt
      updatedAt
      esg
      doNotRecommend
      type
      specialType
      category {
        id
        name
        foreColor
        backColor
      }
      draftedLangs {
        lang
        title
      }
      hasSibling
      publishedLangs {
        lang
        title
      }
      title
    }
  }
`

export const updateEpisode = gql`
  mutation UpdateEpisode($input: UpdateEpisodeInput!) {
    updateEpisode(input: $input) {
      id
      lang
      status
      stage
      title
      text
      image
      esg
      createdAt
      updatedAt
      doNotRecommend
      type
      specialType
      recommendStartsAt
      recommendEndsAt
      category {
        id
        name
      }
      topics {
        primary
        topic {
          id
          createdAt
          updatedAt
          name
          translations {
            lang
            name
          }
        }
      }
      sdgs {
        primary
        sdg {
          id
          createdAt
          updatedAt
          name
          image
          translations {
            lang
            image
            name
          }
        }
      }
      sdgTargets {
        primary
        sdgTarget {
          id
          translations {
            lang
            description
          }
        }
      }
      translations {
        id
        lang
        stage
        title
        createdAt
        updatedAt
        slides {
          slideType
          textColor
          background
          decor
          box {
            boxShape
            boxBackColor
          }
          ... on EmbedSlide {
            id
            slideType
            background
            decor
            box {
              boxShape
              boxBackColor
            }
            textColor
            embedSlideText: text
            embedSlideCaption: caption
            embedSlideCaptionTitle: captionTitle
            embedUrl
          }
          ... on FullImageSlide {
            id
            slideType
            background
            decor
            box {
              boxShape
              boxBackColor
            }
            textColor
            FullImageSlideImage: image {
              type
              url
              description
            }
            caption
            captionTitle
          }
          ... on FullTextSlide {
            id
            slideType
            textColor
            background
            decor
            box {
              boxShape
              boxBackColor
            }
            FullTextSlideText: text
          }
          ... on PortraitSlide {
            id
            slideType
            background
            decor
            box {
              boxShape
              boxBackColor
            }
            textColor
            PortraitSlideImage: image {
              type
              url
              description
            }
            PortraitSlideText: text
          }
          ... on QuizSlide {
            id
            slideType
            background
            decor
            box {
              boxShape
              boxBackColor
            }
            textColor
            quizType
            question
            opt1 {
              text
              image
            }
            opt2 {
              text
              image
            }
            opt3 {
              text
              image
            }
            opt4 {
              text
              image
            }
            correctAnswer
          }
          ... on QuoteSlide {
            id
            slideType
            background
            decor
            box {
              boxShape
              boxBackColor
            }
            textColor
            QuoteSlideImage: image {
              type
              url
              description
            }
            author
            authorTitle
            text
          }
          ... on TextImageSlide {
            id
            slideType
            textColor
            background
            decor
            box {
              boxShape
              boxBackColor
            }
            SlideImage: image {
              type
              url
              description
            }
            text
          }
          ... on TitleSlide {
            id
            slideType
            textColor
            background
            decor
            tagBox {
              tagBoxBackColor
              tagBoxTextColor
              tagBoxText
            }
            box {
              boxShape
              boxBackColor
            }
            title
            description
            image {
              type
              url
              description
            }
          }
        }
        quiz {
          id
          slideType
          background
          decor
          box {
            boxShape
            boxBackColor
          }
          textColor
          quizType
          question
          opt1 {
            text
            image
          }
          opt2 {
            text
            image
          }
          opt3 {
            text
            image
          }
          opt4 {
            text
            image
          }
          correctAnswer
        }
      }
    }
  }
`

export const publishEpisode = gql`
  mutation PublishEpisode($input: PublishEpisodeInput!) {
    publishEpisode(input: $input) {
      id
    }
  }
`

export const archiveEpisode = gql`
  mutation ArchiveEpisode($input: ArchiveEpisodeInput!) {
    archiveEpisode(input: $input) {
      id
      archived
    }
  }
`

export const unArchiveEpisode = gql`
  mutation UnArchiveEpisode($input: UnArchiveEpisodeInput!) {
    unArchiveEpisode(input: $input) {
      archived
      id
    }
  }
`

export const publishEpisodeTranslation = gql`
  mutation PublishEpisodeTranslation($input: PublishEpisodeTranslationInput) {
    publishEpisodeTranslation(input: $input) {
      id
    }
  }
`

export const upsertEpisodeTranslation = gql`
  mutation UpsertEpisodeTranslation($input: EpisodeTranslationInput!) {
    upsertEpisodeTranslation(input: $input) {
      id
    }
  }
`

export const createJourney = gql`
  mutation CreateJourney($input: CreateJourneyInput!) {
    createJourney(input: $input) {
      id
      lang
      status
      stage
      title
      text
      image
      type
      esg
      category {
        id
        name
        foreColor
        backColor
      }
      topics {
        primary
        topic {
          createdAt
          id
          name
          translations {
            lang
            name
          }
          updatedAt
        }
      }
      sdgs {
        primary
        sdg {
          id
          name
          image
        }
      }
      sdgTargets {
        primary
        sdgTarget {
          id
          translations {
            lang
            description
          }
        }
      }
      episodes {
        sorting
        episode {
          id
          lang
          title
          createdAt
          hasSibling
          stage
          status
          topics {
            primary
            topic {
              createdAt
              updatedAt
              id
              name
              translations {
                lang
                name
              }
            }
          }
          sdgs {
            primary
            sdg {
              id
              name
              image
            }
          }
          sdgTargets {
            primary
            sdgTarget {
              id
              translations {
                lang
                description
              }
            }
          }
          text
          image
          translations {
            id
            lang
            stage
            title
            createdAt
            updatedAt
          }
          updatedAt
          publishedLangs {
            lang
            title
          }
          draftedLangs {
            lang
            title
          }
        }
      }
      publishedLangs {
        lang
        title
      }
      hasSibling
      createdAt
      updatedAt
    }
  }
`

export const updateJourney = gql`
  mutation UpdateJourney($input: UpdateJourneyInput!) {
    updateJourney(input: $input) {
      id
      lang
      status
      stage
      title
      text
      image
      type
      esg
      category {
        id
        name
      }
      topics {
        primary
        topic {
          createdAt
          id
          name
          translations {
            lang
            name
          }
          updatedAt
        }
      }
      sdgs {
        primary
        sdg {
          id
          name
          image
        }
      }
      sdgTargets {
        primary
        sdgTarget {
          id
          translations {
            lang
            description
          }
        }
      }
      episodes {
        sorting
        episode {
          id
          lang
          title
          createdAt
          hasSibling
          stage
          status
          topics {
            primary
            topic {
              createdAt
              updatedAt
              id
              name
              translations {
                lang
                name
              }
            }
          }
          sdgs {
            primary
            sdg {
              id
              name
              image
            }
          }
          sdgTargets {
            primary
            sdgTarget {
              id
              translations {
                lang
                description
              }
            }
          }
          text
          image
          translations {
            id
            lang
            stage
            title
            createdAt
            updatedAt
          }
          updatedAt
          publishedLangs {
            lang
            title
          }
          draftedLangs {
            lang
            title
          }
        }
      }
      publishedLangs {
        lang
        title
      }
      hasSibling
      createdAt
      updatedAt
    }
  }
`

export const publishJourney = gql`
  mutation PublishJourney($input: PublishJourneyInput!) {
    publishJourney(input: $input) {
      id
    }
  }
`

export const archiveJourney = gql`
  mutation ArchiveJourney($input: ArchiveJourneyInput!) {
    archiveJourney(input: $input) {
      id
    }
  }
`

export const deleteMedia = gql`
  mutation MediaDelete($input: DeleteMediaInput!) {
    mediaDelete(input: $input) {
      id
      deleted
    }
  }
`

export const upsertTeamJourney = gql`
  mutation UpsertTeamJourney($input: upsertTeamJourneyInput!) {
    upsertTeamJourney(input: $input) {
      items {
        journey {
          id
          lang
          status
          stage
          title
          text
          image
          episodes {
            sorting
            episode {
              id
            }
          }
          publishedLangs {
            lang
            title
          }
          hasSibling
        }
        sorting
      }
    }
  }
`

export const upsertDailyEpisode = gql`
  mutation UpsertDailyEpisode($input: DailyEpisodeInput!) {
    upsertDailyEpisode(input: $input) {
      sk
      episode {
        id
        image
        title
      }
    }
  }
`

export const updateMediaMetadata = gql`
  mutation MediaMetadataUpdate($input: UpdateMediaMetadataInput!) {
    mediaMetadataUpdate(input: $input) {
      id
      url
      title
      fileType
      description
      size
      category
      createdAt
      updatedAt
      mediaTags
    }
  }
`

export const updateChannel = gql`
  mutation UpdateChannel($input: UpdateChannelInput!) {
    updateChannel(input: $input) {
      id
      teamId
      projectId
      type
      oldTeamId
      issuer
      clientCredentialId
      webhookUrl
      reportUrl
      privacyVersion
      createdAt
      updatedAt
    }
  }
`

export const upsertChannelJourney = gql`
  mutation UpsertChannelJourney($input: UpsertChannelJourneyInput!) {
    upsertChannelJourney(input: $input) {
      items {
        startsAt
        endsAt
        journey {
          id
          lang
          status
          stage
          title
          text
          image
          publishedLangs {
            lang
            title
          }
          hasSibling
          createdAt
          updatedAt
        }
      }
    }
  }
`

export const createChannel = gql`
  mutation CreateChannel($input: CreateChannelInput!) {
    createChannel(input: $input) {
      id
      teamId
      projectId
      type
      oldTeamId
      issuer
      clientCredentialId
      webhookUrl
      privacyVersion
      createdAt
      updatedAt
    }
  }
`

export const upsertNews = gql`
  mutation NewsUpsert($input: NewsInput!) {
    newsUpsert(input: $input) {
      startsAt
      endsAt
      id
      teamId
      updatedAt
      points
    }
  }
`

export const upsertDocumentNews = gql`
  mutation DocumentUpsert($input: DocumentInput) {
    documentUpsert(input: $input) {
      ... on NewsDocument {
        items {
          lang
          type
          isDefault: default
          body {
            ... on BodyEmbedSlice {
              sliceType
              embedUrl
              embedText
              embedCaption
              embedCaptionTitle
            }
            ... on BodyImageSlice {
              sliceType
              imgUrl
              imgCaption
              imgCaptionTitle
            }
            ... on BodyQuoteSlice {
              sliceType
              quoteText
              author
              authorTitle
            }
            ... on BodyTextSlice {
              sliceType
              text
            }
          }
          updatedAt
          title
          image
          tagline
          redirectUrl
        }
        parentId
      }
    }
  }
`

export const upsertAction = gql`
  mutation ActionUpsert($input: UpsertActionInput!) {
    actionUpsert(input: $input) {
      id
      updatedAt
      handle
      isRepeatable
      image
      timesPerDay
      featured
      teamId
      doNotRecommend
      savingMetrics {
        metrics {
          action
          activity
          co2Saving
          energySaving
          waterSaving
        }
        location {
          ... on Region {
            id
            name
          }
          ... on SubRegion {
            id
            name
          }
          ... on DefaultLocation {
            id
            name
          }
          ... on Country {
            id
            name
          }
        }
      }
      sdgs {
        primary
        sdg {
          id
          name
          image
        }
      }
      sdgTargets {
        primary
        sdgTarget {
          id
          translations {
            description
            lang
          }
        }
      }
      category {
        id
        name
        translations {
          lang
          name
        }
        foreColor
        backColor
      }
      document {
        parentId
      }
    }
  }
`

export const upsertDocumentAction = gql`
  mutation DocumentUpsert($input: DocumentInput) {
    documentUpsert(input: $input) {
      ... on ActionDocument {
        items {
          isDefault: default
          lang
          title
          type
          updatedAt
          assumptions
          body {
            ... on BodyEmbedSlice {
              sliceType
              embedUrl
              embedText
              embedCaption
              embedCaptionTitle
            }
            ... on BodyImageSlice {
              sliceType
              imgUrl
              imgCaption
              imgCaptionTitle
            }
            ... on BodyQuoteSlice {
              sliceType
              quoteText
              author
              authorTitle
            }
            ... on BodyTextSlice {
              sliceType
              text
            }
          }
        }
        parentId
      }
    }
  }
`

export const deleteNews = gql`
  mutation NewsDelete($input: NewsDeleteInput!) {
    newsDelete(input: $input) {
      deleted
      id
    }
  }
`

export const sortNews = gql`
  mutation NewsSort($input: SortedNewsInput!) {
    newsSort(input: $input) {
      items {
        document {
          items {
            body {
              ... on BodyEmbedSlice {
                sliceType
                embedUrl
                embedText
                embedCaption
                embedCaptionTitle
              }
              ... on BodyImageSlice {
                sliceType
                imgUrl
                imgCaption
                imgCaptionTitle
              }
              ... on BodyQuoteSlice {
                sliceType
                quoteText
                author
                authorTitle
              }
              ... on BodyTextSlice {
                sliceType
                text
              }
            }
            isDefault: default
            image
            lang
            parentId
            redirectUrl
            tagline
            title
            type
            updatedAt
          }
        }
        endsAt
        id
        startsAt
        teamId
        updatedAt
        points
      }
    }
  }
`

export const upsertActionGroup = gql`
  mutation ActionGroupUpsert($input: UpsertActionGroupInput!) {
    actionGroupUpsert(input: $input) {
      id
      updatedAt
      handle
      image
      maxIterations
      canBecomeHabit
      featured
      footprintPrediction
      actions {
        action {
          id
          image
          isRepeatable
          updatedAt
          document {
            items {
              isDefault: default
              lang
              title
            }
          }
          savingMetrics {
            metrics {
              action
              activity
              co2Saving
              energySaving
              waterSaving
            }
          }
          category {
            id
            name
            foreColor
            backColor
          }
        }
      }
      sdgs {
        primary
        sdg {
          id
          name
          image
        }
      }
      sdgTargets {
        primary
        sdgTarget {
          id
          translations {
            description
            lang
          }
        }
      }
      badge {
        id
        image
        badgeType
        updatedAt
        document {
          parentId
          items {
            title
            lang
            isDefault: default
          }
        }
      }
      document {
        parentId
      }
      category {
        id
        name
        foreColor
        backColor
        translations {
          lang
          name
        }
      }
      footprint {
        pk
        questionId
        sk
      }
    }
  }
`

export const upsertDocumentActionGroup = gql`
  mutation DocumentUpsert($input: DocumentInput) {
    documentUpsert(input: $input) {
      ... on ActionGroupDocument {
        parentId
        items {
          updatedAt
          type
          title
          lang
          isDefault: default
          benchmark
          body {
            sliceType
            ... on BodyEmbedSlice {
              sliceType
              embedUrl
              embedText
              embedCaption
              embedCaptionTitle
            }
            ... on BodyImageSlice {
              sliceType
              imgUrl
              imgCaption
              imgCaptionTitle
            }
            ... on BodyQuoteSlice {
              sliceType
              quoteText
              author
              authorTitle
            }
            ... on BodyTextSlice {
              sliceType
              text
            }
          }
        }
      }
    }
  }
`

export const upsertBadge = gql`
  mutation BadgeUpsert($input: BadgeUpsertInput!) {
    badgeUpsert(input: $input) {
      badgeType
      id
      image
      updatedAt
      category {
        id
        name
        foreColor
        backColor
      }
    }
  }
`

export const upsertDocumentBadge = gql`
  mutation DocumentUpsert($input: DocumentInput) {
    documentUpsert(input: $input) {
      ... on BadgeDocument {
        parentId
        items {
          title
          type
          updatedAt
          parentId
          lang
          isDefault: default
          body {
            sliceType
            ... on BodyEmbedSlice {
              sliceType
              embedUrl
              embedText
              embedCaption
              embedCaptionTitle
            }
            ... on BodyImageSlice {
              sliceType
              imgUrl
              imgCaption
              imgCaptionTitle
            }
            ... on BodyQuoteSlice {
              sliceType
              quoteText
              author
              authorTitle
            }
            ... on BodyTextSlice {
              sliceType
              text
            }
          }
        }
      }
    }
  }
`

export const deleteAction = gql`
  mutation ActionDelete($input: DeleteActionInput!) {
    actionDelete(input: $input) {
      id
      deleted
    }
  }
`

export const deleteActionGroup = gql`
  mutation ActionGroupDelete($input: DeleteActionGroupInput!) {
    actionGroupDelete(input: $input) {
      id
      deleted
    }
  }
`

export const createChallenge = gql`
  mutation ChallengeCreate($input: CreateChallengeInput!) {
    challengeCreate(input: $input) {
      id
      oldId
      uid
      startsAt
      endsAt
      currentAmount
      targetAmount
      personalTargetAmount
      personalTargetPoints
      name
      type
      updatedAt
      leaderboardGroupType
      leaderboardMinMembers
      userCount
      privacyPolicy
      versionType
      metric {
        id
        lang
        name
        type
        unit
        color
        icon
      }
      sorting
      team {
        id
        oldId
        uid
        name
        accessType
        stage
        featuredSorting
        homeFeaturedSorting
        isHomeFeatured
        stakeholderType
        visibilityOptions {
          leaderboard
          members
          metric
        }
      }
    }
  }
`

export const upsertDocumentChallenge = gql`
  mutation DocumentUpsert($input: DocumentInput) {
    documentUpsert(input: $input) {
      ... on ChallengeDocument {
        parentId
        items {
          isDefault: default
          image
          lang
          parentId
          sponsor
          subtitle
          title
          type
          body {
            sliceType
            ... on BodyEmbedSlice {
              sliceType
              embedUrl
              embedText
              embedCaption
              embedCaptionTitle
            }
            ... on BodyImageSlice {
              sliceType
              imgUrl
              imgCaption
              imgCaptionTitle
            }
            ... on BodyQuoteSlice {
              sliceType
              quoteText
              author
              authorTitle
            }
            ... on BodyTextSlice {
              sliceType
              text
            }
          }
          prizes {
            ... on BodyImageSlice {
              sliceType
              imgUrl
              imgCaption
              imgCaptionTitle
            }
          }
        }
      }
    }
  }
`

export const updateChallenge = gql`
  mutation ChallengeUpdate($input: UpdateChallengeInput!) {
    challengeUpdate(input: $input) {
      id
      oldId
      uid
      startsAt
      endsAt
      currentAmount
      targetAmount
      personalTargetAmount
      personalTargetPoints
      name
      type
      updatedAt
      leaderboardGroupType
      leaderboardMinMembers
      privacyPolicy
      versionType
      metric {
        id
        lang
        name
        type
        unit
        color
        icon
      }
      sorting
      team {
        id
        oldId
        uid
        name
        accessType
        stage
        featuredSorting
        homeFeaturedSorting
        isHomeFeatured
        stakeholderType
        visibilityOptions {
          leaderboard
          members
          metric
        }
      }
    }
  }
`

export const deleteChallenge = gql`
  mutation ChallengeDelete($input: DeleteChallengeInput!) {
    challengeDelete(input: $input) {
      id
      deleted
    }
  }
`

export const challengeAutojoin = gql`
  mutation ChallengeAutojoin($input: AutoJoinChallengeInput!) {
    challengeAutojoin(input: $input) {
      startedAt
    }
  }
`

export const footprintActionGroupCreate = gql`
  mutation FootprintActionGroupCreate($input: ActionGroupCreateInput) {
    footprintActionGroupCreate(input: $input) {
      pk
      questionId
      sk
    }
  }
`

export const footprintActionGroupDelete = gql`
  mutation FootprintActionGroupDelete($input: ActionGroupDeleteInput) {
    footprintActionGroupDelete(input: $input) {
      pk
      questionId
      sk
    }
  }
`

export const sdgUpsert = gql`
  mutation SdgUpsert($input: UpsertSdgInput!) {
    sdgUpsert(input: $input) {
      id
      name
      image
      code
      translations {
        name
        image
        lang
      }
      updatedAt
    }
  }
`

export const sdgTargetUpsert = gql`
  mutation SdgTargetUpsert($input: UpsertSdgTargetInput!) {
    sdgTargetUpsert(input: $input) {
      id
      code
      translations {
        description
        lang
      }
      updatedAt
    }
  }
`

export const categoryUpsert = gql`
  mutation CategoryUpsert($input: UpsertCategoryInput!) {
    categoryUpsert(input: $input) {
      id
      name
      code
      backColor
      backTagColor
      foreColor
      mainCategory {
        id
        name
      }
      translations {
        name
        lang
      }
      updatedAt
    }
  }
`

export const mainCategoryUpsert = gql`
  mutation MainCategoryUpsert($input: UpsertMainCategoryInput!) {
    mainCategoryUpsert(input: $input) {
      id
      name
      code
      backColor
      backTagColor
      foreColor
      translations {
        name
        description
        cta
        lang
      }
      updatedAt
    }
  }
`

export const topicUpsert = gql`
  mutation TopicUpsert($input: UpsertTopicInput!) {
    topicUpsert(input: $input) {
      id
      name
      code
      mainTopic {
        id
        name
      }
      translations {
        name
        lang
      }
      updatedAt
    }
  }
`

export const teamCreate = gql`
  mutation TeamCreate($input: CreateTeamInput!) {
    teamCreate(input: $input) {
      accessType
      featuredSorting
      homeFeaturedSorting
      isHomeFeatured
      id
      name
      stakeholderType
      organizationType
      uid
      updatedAt
      isPrimary
      suggestFootprint
      visibilityOptions {
        leaderboard
        members
        metric
      }
    }
  }
`

export const documentUpsertTeam = gql`
  mutation DocumentUpsert($input: DocumentInput) {
    documentUpsert(input: $input) {
      ... on TeamDocument {
        items {
          title
          cover
          isDefault: default
          lang
          logo
          parentId
          type
          updatedAt
          body {
            ... on BodyEmbedSlice {
              sliceType
              embedUrl
              embedText
              embedCaption
              embedCaptionTitle
            }
            ... on BodyImageSlice {
              sliceType
              imgUrl
              imgCaption
              imgCaptionTitle
            }
            ... on BodyQuoteSlice {
              sliceType
              quoteText
              author
              authorTitle
            }
            ... on BodyTextSlice {
              sliceType
              text
            }
          }
          home {
            image
            title
            subtitle
          }
        }
        parentId
      }
    }
  }
`

export const teamUpdate = gql`
  mutation TeamUpdate($input: UpdateTeamInput!) {
    teamUpdate(input: $input) {
      accessType
      canLeave
      featuredSorting
      homeFeaturedSorting
      isHomeFeatured
      isPrimary
      suggestFootprint
      groups {
        groupId
        name
        userCount
      }
      id
      name
      metrics {
        amount
        metric {
          color
          icon
          id
          lang
          name
          type
          unit
        }
      }
      oldId
      stage
      stakeholderType
      organizationType
      uid
      updatedAt
      userCount
      visibilityOptions {
        leaderboard
        members
        metric
      }
      document {
        parentId
        items {
          parentId
          lang
          type
          isDefault: default
          body {
            ... on BodyEmbedSlice {
              sliceType
              embedUrl
              embedText
              embedCaption
              embedCaptionTitle
            }
            ... on BodyImageSlice {
              sliceType
              imgUrl
              imgCaption
              imgCaptionTitle
            }
            ... on BodyQuoteSlice {
              sliceType
              quoteText
              author
              authorTitle
            }
            ... on BodyTextSlice {
              sliceType
              text
            }
          }
          home {
            image
            title
            subtitle
          }
          updatedAt
          title
          logo
          cover
        }
      }
      availableAtLocations {
        ... on DefaultLocation {
          id
          name
        }
        ... on Country {
          id
          name
        }
        ... on Region {
          id
          name
        }
        ... on SubRegion {
          id
          name
        }
      }
      restrictedAtLocations {
        ... on DefaultLocation {
          id
          name
        }
        ... on Country {
          id
          name
        }
        ... on Region {
          id
          name
        }
        ... on SubRegion {
          id
          name
        }
      }
    }
  }
`

export const teamUserUpsert = gql`
  mutation TeamUserUpsert($input: TeamUserUpsertInput!) {
    teamUserUpsert(input: $input) {
      group {
        groupId
        name
      }
      isAdmin
      user {
        sub
        email
        first_name
        last_name
        uid
      }
    }
  }
`

export const activationCodeCreate = gql`
  mutation ActivationCodeCreate($input: ActivationCodeCreateInput!) {
    activationCodeCreate(input: $input) {
      ... on TeamActivationCode {
        code
        codeType
        groupId
        iterationCount
        maxIteration
        policy
        teamId
      }
    }
  }
`

export const activationCodeDelete = gql`
  mutation ActivationCodeDelete($input: ActivationCodeDeleteInput!) {
    activationCodeDelete(input: $input) {
      deleted
    }
  }
`

export const teamDelete = gql`
  mutation TeamDelete($input: DeleteTeamInput!) {
    teamDelete(input: $input)
  }
`

export const teamUserDelete = gql`
  mutation TeamUserDelete($input: TeamUserDeleteInput) {
    teamUserDelete(input: $input)
  }
`

export const teamContractCreate = gql`
  mutation TeamContractCreate($input: TeamContractCreateInput!) {
    teamContractCreate(input: $input) {
      id
      startDate
      endDate
      sellingPrice
      targetMembers
      activatedAt
    }
  }
`

export const teamContractUpdate = gql`
  mutation TeamContractUpdate($input: TeamContractUpdateInput!) {
    teamContractUpdate(input: $input) {
      id
      startDate
      endDate
      sellingPrice
      targetMembers
      activatedAt
    }
  }
`

export const teamContractDelete = gql`
  mutation TeamContractDelete($input: TeamContractDeleteInput!) {
    teamContractDelete(input: $input)
  }
`

export const teamContractActivate = gql`
  mutation TeamContractActivate($input: TeamContractActivateInput!) {
    teamContractActivate(input: $input) {
      id
      startDate
      endDate
      sellingPrice
      targetMembers
      activatedAt
    }
  }
`

export const productUpsert = gql`
  mutation ProductUpsert($input: UpsertProductInput!) {
    productUpsert(input: $input) {
      availability
      boughtCount
      defaultCurrency
      hasSibling
      handle
      id
      image
      inventoryCount
      limitPerUser
      prices {
        amount
        currency
      }
      projectId
      sponsoredBy
      stage
      status
      sorting
      team {
        id
        document {
          items {
            title
            isDefault: default
          }
        }
      }
      type
      gamificationType
      visibility
      availableAtLocations {
        ... on DefaultLocation {
          id
          name
          __typename
        }
        ... on Country {
          id
          name
          __typename
        }
        ... on Region {
          id
          name
          __typename
        }
        ... on SubRegion {
          id
          name
          __typename
        }
      }
      updatedAt
    }
  }
`

export const upsertDocumentProduct = gql`
  mutation DocumentUpsert($input: DocumentInput) {
    documentUpsert(input: $input) {
      ... on ProductDocument {
        items {
          isDefault: default
          lang
          parentId
          title
          updatedAt
          body {
            ... on BodyEmbedSlice {
              sliceType
              embedUrl
              embedText
              embedCaption
              embedCaptionTitle
            }
            ... on BodyImageSlice {
              sliceType
              imgUrl
              imgCaption
              imgCaptionTitle
            }
            ... on BodyQuoteSlice {
              sliceType
              quoteText
              author
              authorTitle
            }
            ... on BodyTextSlice {
              sliceType
              text
            }
          }
        }
      }
    }
  }
`

export const productPublish = gql`
  mutation ProductPublish($input: PublishProductInput!) {
    productPublish(input: $input) {
      stage
    }
  }
`

export const nftCatalogUpdate = gql`
  mutation NftCatalogUpdate($input: UpdateNftCatalogInput!) {
    nftCatalogUpdate(input: $input) {
      id
      image
      name
      description
      projectId
      sourceId
      sourceKind
      kind
      status
      mintAt
      symbol
      attributes {
        trait_type
        value
      }
      contracts
    }
  }
`

export const nftCatalogCreate = gql`
  mutation NftCatalogCreate($input: CreateNftCatalogInput!) {
    nftCatalogCreate(input: $input) {
      id
    }
  }
`

export const syncNotionEpisode = gql`
  mutation SyncNotionEpisode($input: SyncNotionEpisodeInput!) {
    syncNotionEpisode(input: $input) {
      id
      synced
    }
  }
`
export const notificationTemplateUpsert = gql`
  mutation NotificationTemplateUpsert(
    $input: UpsertNotificationTemplateInput!
  ) {
    notificationTemplateUpsert(input: $input) {
      id
      updatedAt
      handle
      stage
      status
      format
      type
      validationStatus
      invalidLangs {
        lang
        validation {
          field
          errors
        }
      }
      defaultDataJson
      hasSibling
      requiredEnricherIds
    }
  }
`

export const upsertDocumentNotificationTemplate = gql`
  mutation DocumentUpsert($input: DocumentInput) {
    documentUpsert(input: $input) {
      ... on NotificationTemplateDocument {
        parentId
        items {
          lang
          isDefault: default
          parentId
          title
          subtitle
          type
          contentBody
          richContentBody
        }
      }
    }
  }
`

export const notificationTemplateValidate = gql`
  mutation NotificationTemplateValidate(
    $input: ValidateNotificationTemplateInput!
  ) {
    notificationTemplateValidate(input: $input) {
      id
      invalidLangs {
        lang
        validation {
          errors
          field
        }
      }
      validationStatus
    }
  }
`

export const notificationTemplatePublish = gql`
  mutation NotificationTemplatePublish(
    $input: PublishNotificationTemplateInput!
  ) {
    notificationTemplatePublish(input: $input) {
      stage
    }
  }
`

export const missionCatalogUpsert = gql`
  mutation MissionCatalogUpsert($input: UpsertMissionCatalogInput!) {
    missionCatalogUpsert(input: $input) {
      id
      handle
      image
      projectId
      stage
      status
      timeframe
      type
      specialType
      updatedAt
      difficultyLevel
      target
      hasSibling
      suspended
      team {
        id
        document {
          items {
            title
            isDefault: default
          }
        }
      }
    }
  }
`

export const upsertDocumentMission = gql`
  mutation DocumentUpsert($input: DocumentInput) {
    documentUpsert(input: $input) {
      ... on MissionCatalogDocument {
        items {
          isDefault: default
          lang
          parentId
          title
          updatedAt
        }
      }
    }
  }
`

export const missionCatalogPublish = gql`
  mutation MissionCatalogPublish($input: PublishMissionCatalogInput!) {
    missionCatalogPublish(input: $input) {
      stage
    }
  }
`

export const upsertDocumentLeague = gql`
  mutation DocumentUpsert($input: DocumentInput) {
    documentUpsert(input: $input) {
      ... on LeagueDocument {
        parentId
        items {
          body {
            sliceType
            ... on BodyEmbedSlice {
              sliceType
              embedUrl
              embedText
              embedCaption
              embedCaptionTitle
            }
            ... on BodyImageSlice {
              sliceType
              imgUrl
              imgCaption
              imgCaptionTitle
            }
            ... on BodyQuoteSlice {
              sliceType
              quoteText
              author
              authorTitle
            }
            ... on BodyTextSlice {
              sliceType
              text
            }
          }
          isDefault: default
          lang
          logo
          parentId
          title
          type
        }
      }
    }
  }
`

export const surveyUpsert = gql`
  mutation SurveyUpsert($input: UpsertSurveyInput!) {
    surveyUpsert(input: $input) {
      id
      provider
      stage
      status
      handle
      teamId
      startsAt
      endsAt
      team {
        id
        document {
          items {
            title
            isDefault: default
          }
        }
      }
      projectId
      suspended
      storeResponses
      useSecret
      hasSibling
      document {
        parentId
        items {
          title
          lang
          isDefault: default
          formId
          formUrl
          webhookUrl
          webhookSecret
          type
          updatedAt
          linkMode
          body {
            ... on BodyEmbedSlice {
              sliceType
              embedUrl
              embedText
              embedCaption
              embedCaptionTitle
            }
            ... on BodyImageSlice {
              sliceType
              imgUrl
              imgCaption
              imgCaptionTitle
            }
            ... on BodyQuoteSlice {
              sliceType
              quoteText
              author
              authorTitle
            }
            ... on BodyTextSlice {
              sliceType
              text
            }
          }
        }
      }
      createdAt
      updatedAt
      createdAtT
      updatedAtT
      lastUpdatedAt
    }
  }
`

export const surveyPublish = gql`
  mutation SurveyPublish($input: PublishSurveyInput!) {
    surveyPublish(input: $input) {
      id
      stage
      document {
        parentId
        items {
          title
          lang
          isDefault: default
          formId
          formUrl
          webhookUrl
          webhookSecret
          linkMode
          type
          updatedAt
          body {
            ... on BodyEmbedSlice {
              sliceType
              embedUrl
              embedText
              embedCaption
              embedCaptionTitle
            }
            ... on BodyImageSlice {
              sliceType
              imgUrl
              imgCaption
              imgCaptionTitle
            }
            ... on BodyQuoteSlice {
              sliceType
              quoteText
              author
              authorTitle
            }
            ... on BodyTextSlice {
              sliceType
              text
            }
          }
        }
      }
    }
  }
`

export const surveyDraftDelete = gql`
  mutation SurveyDraftDelete($input: DeleteSurveyInput!) {
    surveyDraftDelete(input: $input) {
      id
      deleted
    }
  }
`

export const surveyLink = gql`
  mutation SurveyLink($input: LinkSurveyInput!) {
    surveyLink(input: $input) {
      id
      document {
        parentId
        items {
          title
          lang
          isDefault: default
          formId
          formUrl
          webhookUrl
          webhookSecret
          type
          updatedAt
          linkMode
          body {
            ... on BodyEmbedSlice {
              sliceType
              embedUrl
              embedText
              embedCaption
              embedCaptionTitle
            }
            ... on BodyImageSlice {
              sliceType
              imgUrl
              imgCaption
              imgCaptionTitle
            }
            ... on BodyQuoteSlice {
              sliceType
              quoteText
              author
              authorTitle
            }
            ... on BodyTextSlice {
              sliceType
              text
            }
          }
        }
      }
    }
  }
`

export const upsertDocumentSurvey = gql`
  mutation DocumentUpsert($input: DocumentInput) {
    documentUpsert(input: $input) {
      ... on SurveyDocument {
        parentId
        items {
          body {
            sliceType
            ... on BodyEmbedSlice {
              sliceType
              embedUrl
              embedText
              embedCaption
              embedCaptionTitle
            }
            ... on BodyImageSlice {
              sliceType
              imgUrl
              imgCaption
              imgCaptionTitle
            }
            ... on BodyQuoteSlice {
              sliceType
              quoteText
              author
              authorTitle
            }
            ... on BodyTextSlice {
              sliceType
              text
            }
          }
          title
          lang
          isDefault: default
          formId
          formUrl
          webhookUrl
          webhookSecret
          linkMode
          type
          updatedAt
        }
      }
    }
  }
`

export const newsQuizUpsert = gql`
  mutation NewsQuizUpsert($input: NewsQuizInput!) {
    newsQuizUpsert(input: $input) {
      id
      order
      newsId
      updatedAt
      document {
        parentId
        items {
          isDefault: default
          lang
          question
          opt1
          opt2
          opt3
          opt4
          correctAnswer
          explanation
          type
        }
      }
    }
  }
`

export const upsertDocumentNewsQuiz = gql`
  mutation DocumentUpsert($input: DocumentInput) {
    documentUpsert(input: $input) {
      ... on NewsQuizDocument {
        parentId
        items {
          isDefault: default
          lang
          question
          opt1
          opt2
          opt3
          opt4
          correctAnswer
          explanation
          type
        }
      }
    }
  }
`

export const newsQuizDelete = gql`
  mutation NewsQuizDelete($input: NewsQuizDeleteInput!) {
    newsQuizDelete(input: $input) {
      id
      deleted
    }
  }
`

export const translationsAiTranslate = gql`
  mutation TranslationsAiTranslate($input: TranslationsAiInput!) {
    translationsAiTranslate(input: $input) {
      jobId
    }
  }
`
